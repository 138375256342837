<template>
  <div class="content-container">
    <PageTitle :title="$t('FAQ.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('CREATE_FAQ.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')"
                :route-name="ROUTE_NAMES_CMS.FAQ"
                :disabled="allowLeaving"/>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData"
                 class="page-form"
                 :on-form-data-update="updatedForm">
      <template #form-content>

        <!-- question -->
        <InputField :field-title="$t('CREATE_FAQ.QUESTION')"
                    :field-name="'question'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('CREATE_FAQ.QUESTION_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="form-element"/>

        <!-- answer -->
        <div class="rich-text-field-wrapper">
          <RichTextField :field-title="$t('CREATE_FAQ.ANSWER')"
                         :input-callback="instructionInput"
                         :error-text="richTextError"
          />
        </div>

        <!-- Type of question -->
        <Dropdown id="category"
                  :field-title="$t('CREATE_FAQ.CATEGORY')"
                  :dropdown-options="categories"
                  :placeholder="$t('CREATE_FAQ.CATEGORY_PLACEHOLDER')"
                  :field-name="'faqCategoryId'"
                  :selected-option="categories[0]"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :callback="() => {}"
                  :api-error-text="''"
                  class="form-element"/>

        <!-- Status -->
        <Dropdown id="status"
                  :field-title="$t('CREATE_FAQ.STATUS')"
                  :dropdown-options="status"
                  :selected-option="status[0]"

                  :placeholder="$t('CREATE_FAQ.STATUS_PLACEHOLDER')"
                  :field-name="'isActivated'"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :callback="() => {}"
                  :api-error-text="''"
                  class="form-element"/>

        <Checkbox
          :field-title="''"
          :field-name="'isTopTen'"
          :rules="''"
          :text="$t('CREATE_FAQ.TOP_TEN')"/>


      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('CREATE_FAQ.CREATE') }}
        </button>
      </template>

    </FormWrapper>

    <BasicModal v-if="isBasicVersionDisplayed"/>

  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import Dropdown from '@/components/yo-form/Dropdown'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'

import Checkbox from '@/components/yo-form/Checkbox'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { CREATE_FAQ } from '@/store/modules/cms/actions'
import nl from '@/utils/language/nl.json'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'


export default {
  name: 'CreateFaq',
  components: {
    BackButton,
    PageTitle,
    InputField,
    FormWrapper,
    Checkbox,
    Dropdown,
    BasicModal,
    RichTextField,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const topTenValue = ref(false)
    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const instructionValue = ref('')
    const richTextError = ref('')

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.LEAVE_EARLY_CREATE_FAQ
    )

    const currentFormData = ref({})


    /** Categories **/
    const categories = [{ id: 1, name: 'Algemeen' }, { id: 2, name: 'Mijn MediaMasters' }, { id: 3, name: 'Technisch' }, { id: 4, name: 'Account' }]

    /** Group Dropdown **/
    const status = [
      {
        id: 0,
        name: nl.CONCEPT,
      },
      {
        id: 1,
        name: nl.PUBLISHED,
      },
    ]

    function reverseLookup(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].name === name) {
          return list[i].id
        }
      }
    }


    function instructionInput(value) {
      instructionValue.value = value
    }


    /** Form **/
    function validateData(data) {
      data.answer = instructionValue.value

      if (!data.answer || data.answer === '') {
        richTextError.value = nl.CREATE_FAQ.NO_ANSWER_GIVEN
        return false
      }
      data.isTopTen = data.isTopTen ?? false
      data.isActivated = reverseLookup(status, data.isActivated)
      data.faqCategoryId = reverseLookup(categories, data.faqCategoryId)
      createQuestion(data)
    }

    /** Create Class **/
    function createQuestion(data) {
      store.dispatch(CREATE_FAQ, data).then(response => {
        if (response) {
          router.push({ name: ROUTE_NAMES_CMS.FAQ })
        }
      })
    }

    function isEmpty(value) {
      return !value || value === ''
    }

    function allowLeaving() {
      if (isEmpty(currentFormData.value.title) && isEmpty(currentFormData.value.answer)) {
        return true
      }
      modalController.value.setModal(BASIC_MODAL_CONTENT.CANCEL_EARLY_FAQ, leave, null, null, MODAL_NAMES.LEAVE_EARLY_CREATE_FAQ)

      //show confirm leaving popup
      return false
    }

    function leave() {
      modalController.value.resetActiveModal()
      router.push({ name: ROUTE_NAMES_CMS.FAQ })
    }

    function updatedForm(data) {
      currentFormData.value = data
    }



    return {
      ROUTE_NAMES_CMS,

      /** Group Dropdown **/
      status,

      categories,
      topTenValue,
      allowLeaving,
      updatedForm,

      isBasicVersionDisplayed,

      /** Form **/
      validateData,
      instructionInput,
      richTextError,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.rich-text-field-wrapper {
  margin-bottom: rem(28);
}

</style>
